import axios from "axios";

const apiKey = process.env.REACT_APP_API_KEY;
const apiUrl = process.env.REACT_APP_API_URL;

export const postData = async (endpoint, data) => {
  try {
    const response = await axios.post(`${apiUrl}${endpoint}`, data, {
      headers: {
        "X-API-KEY": apiKey,  // ✅ Add API key in request headers
        "Content-Type": "application/json",
      },
    });
    return { data: response.data, error: null };
  } catch (error) {
    console.error("API Request Error:", error);
    return { data: null, error: error.response?.data || "Request failed" };
  }
};