import React from 'react';
import { Box, IconButton } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import ContactMailIcon from '@mui/icons-material/ContactMail';

const socialLinks = [
  { icon: <InstagramIcon />, url: 'https://instagram.com/pythia_en' },
  { icon: <XIcon />, url: 'https://twitter.com/pythia_en/' },
  { icon: <ContactMailIcon />, url: 'mailto:pythia@coroebuswm.com' },
];

const pythiaSocialLinks = () => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
      {socialLinks.map((link, index) => (
        <IconButton key={index} href={link.url} target="_blank" rel="noopener noreferrer">
          {link.icon}
        </IconButton>
      ))}
    </Box>
  </Box>
);

export default pythiaSocialLinks;