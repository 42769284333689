import React from 'react';
import { Paper, Button, Box, Modal, Fade, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import LettersOnlyField from '../components/fields/letters-only-field';
import NumericField from '../components/fields/numeric-field';
import EmailField from './fields/email-field';

const AccessRequestForm = ({ control, handleSubmit, onSubmit, errors, closeForm, open }) => (
  <Modal open={!!open} onClose={closeForm} aria-labelledby="access-request-modal">
    <Fade in={open}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <Paper sx={{ p: 3, maxWidth: 300, borderRadius: '12px' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h6" align="left" gutterBottom>Sign up to receive communications from Coroebus Wealth Management.</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <LettersOnlyField name="given_name" control={control} label="First Name" rules={{ required: 'First Name is required' }} error={!!errors.given_name} helperText={errors.given_name?.message} autoComplete="first-name" />
              </Grid>
              <Grid item xs={12}>
                <LettersOnlyField name="family_name" control={control} label="Last Name" rules={{ required: 'Last Name is required' }} error={!!errors.family_name} helperText={errors.family_name?.message} autoComplete="last-name" />
              </Grid>
              <Grid item xs={12}>
                <EmailField name="email" control={control} label="Email" error={!!errors.email} helperText={errors.email?.message} rules={{ required: 'Email is required' }} autoComplete="email" />
              </Grid>
              <Grid item xs={12}>
                <NumericField name="phone_number" control={control} label="Phone Number" rules={{ required: 'Phone Number is required' }} error={!!errors.phone_number} helperText={errors.phone_number?.message} autoComplete="tel" />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button variant="outlined" onClick={closeForm}>Cancel</Button>
              <Button type="submit" variant="contained" color="secondary">Submit</Button>
            </Box>
          </form>
        </Paper>
      </Box>
    </Fade>
  </Modal>
);

// Ensure `open` is required
AccessRequestForm.propTypes = {
  open: PropTypes.bool.isRequired,
  control: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  closeForm: PropTypes.func.isRequired,
};

export default AccessRequestForm;