import React, { useState, useRef } from 'react';
import { Paper, Typography, Box, Modal, Fade, Grid, Button,
  List, ListItem, ListItemText, ListItemButton, Collapse,
  Tooltip, IconButton
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSnackbar } from '../context/snackbar-context';
import { postData } from '../services/data.service';
import LettersOnlyField from '../components/fields/letters-only-field';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import EmailField from '../components/fields/email-field';
import PdfViewer from '../components/pdf-viewer';
import SocialLinks from '../components/social-links';
import InfoCard from '../components/info-card';
import AccessRequestForm from '../components/access-request-form';
import PythiaSocialLinks from '../components/pythia-social-links';
import PYTHIA from '../assets/logoFull.png';
import RSSIcon from '@mui/icons-material/RssFeed';

const pdfFiles = [
  { name: "Information Systems Security & Privacy Policy", file: "/assets/IS2P2.pdf" },
  { name: "Business Continuity Plan", file: "/assets/BCP.pdf" },
  { name: "Code of Ethics", file: "/assets/CoE.pdf" },
  { name: "Our Team", file: "/assets/Form_ADV_Part_2a_01-18-2025.pdf" },
  { name: "Our Services", file: "/assets/Form_ADV_Part_2b_01-18-2025.pdf" },
];

const initialState = {
  given_name: '',
  family_name: '',
  email: '',
};

const WelcomePage = () => {
  const { control, handleSubmit, reset, formState: { errors } } = useForm(
    { defaultValues: initialState }
  );
  const { displayMessage } = useSnackbar();
  const [pdfUrl, setPdf] = useState('');
  const [pythiaShow, setPythiaShow] = useState(false);
  const [open, setOpen] = useState(false);
  const pythiaRef = useRef(null); // 🔥 Ref for Pythia section

  const onSubmit = async (data) => {
    try {
      console.log(data);
      reset();
      setOpen(false);
      await postData('/request_access', data);
      displayMessage('Request Submitted', 'success');

    } catch (error) {
      displayMessage('An error occurred. Please try again later.', 'error');
    }
  };

  const handleCancel = () => {
    setOpen(false);
    reset();
  };

  const togglePythia = () => {
    setPythiaShow((prev) => {
      const newState = !prev;
      if (newState) {
        setTimeout(() => {
          pythiaRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
          reset(initialState);
        }, 300); // Ensures scroll occurs after animation starts
      }
      return newState;
    });
  };

  return (
    <Box>
      <Grid container spacing={3} sx={{ mt: 4 }} justifyContent="center">
        <InfoCard
          title="Who We Are"
          text="Founded by two-time Olympian Taraje Williams-Murray, Coroebus Wealth Management offers strategic financial planning, asset management, and business consulting."
          actions={[{ text: "Learn More", link: "https://www.toexpect.com" }]}
        />
        <InfoCard
          title="Our Mission"
          text="Our mission is to inspire entrepreneurs to execute bespoke strategic plans to achieve lifestyle goals responsibly."
          actions={[{ icon: <RSSIcon />, action: () => setOpen(true) }, { text: "Our Blog", link: "https://www.theagnosticinvestor.com" }]}
        />
        <Grid item xs={12} md={6}>
          <Paper sx={{ m: 3, p: 2, borderRadius: 2, backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
            <img src={PYTHIA} alt="Pythia" style={{ width: '100%', minWidth: 150 }} />
          </Paper>
        </Grid>
        <InfoCard
          title="Predictive Analytics for Entrepreneurs"
          text="Pythia® integrates proven behavioral finance strategies with predictive analytics to help us make smarter financial decisions. We are currently testing our application, but you can request access by clicking below."
          actions={[
            { text: "About", action: togglePythia } // 🔥 Call scroll function
          ]}
        />
      </Grid>

      {/* Pythia Section - Animated Slide Open */}
      <Collapse in={pythiaShow} timeout={500}>
        <Box ref={pythiaRef} sx={{ mt: 4, transition: "0.5s ease-in-out", backgroundColor: 'rgba(255, 255, 255)', p: 3 }}>
          <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 2 }}>
            Empowering Your Financial Future with AI-Driven Insights
          </Typography>
          <Typography variant="h5">
            Pythia® combines advanced AI, predictive analytics, and behavioral finance to help you reach your financial goals with ease and confidence.
          </Typography>

          {/* Key Features Section */}
          <Box>
            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 3, textAlign: 'center', backgroundColor: 'rgba(255, 255, 255, 0.8)', p: 2 }}>
              Key Features
            </Typography>
            <Grid container spacing={4} justifyContent="center">
              {[
                { title: "Automated Financial Guidance", text: "Personalized recommendations based on your financial data help you make informed, proactive choices." },
                { title: "Goal-Oriented Nudges", text: "Receive timely nudges designed to support your goals, whether it’s saving more, reducing debt, or building your retirement fund." },
                { title: "Secure Advisor Access", text: "With your authorization, advisors can access a comprehensive view of your financial landscape to support your planning." },
                { title: "Connect Your Accounts", text: "Easily link your financial accounts for a holistic view of your financial health." },
                { title: "AI Powered Precision", text: "Every recommendation is informed by advanced predictive analytics and behavioral finance principles." },
                { title: "Personalized Insights", text: "Pythia's AI analyzes your data to provide tailored guidance that fits your financial situation and goals." },
                { title: "Customizable Notifications", text: "You choose the delivery method and frequency for nudges that align with your preferences." },
                { title: "Data-driven and Secure", text: "Your data is handled securely, ensuring privacy while delivering a personalized experience." },
                { title: "Looking Ahead", text: "We are constantly improving Pythia to provide you with the best possible experience and insights." },

              ].map((feature, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Paper elevation={3} sx={{ p: 3, textAlign: 'center' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      {feature.title}
                    </Typography>
                    <Typography>{feature.text}</Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Request Access Section */}
          <Box id="access" sx={{ m: 5, maxWidth: 300, mx: 'auto', textAlign: 'center' }}>
            <form onSubmit={handleSubmit(onSubmit)}>  {/* ✅ Changed Box to form */}
              <Grid container spacing={1} justifyContent="center">
                <Grid item xs={12} sx={{ textAlign: 'center', mb: 2 }}>
                  
                    <img src='/assets/pytHeader.png' alt="Pythia" style={{ width: '100%', maxWidth: 200 }} />

                </Grid>
                <Grid item xs={12}>
                  <LettersOnlyField
                    name="given_name"
                    control={control}
                    label="First Name"
                    rules={{ required: 'First Name is required' }}
                    error={!!errors.given_name}
                    helperText={errors.given_name?.message}
                    autoComplete='given-name'
                  />
                </Grid>
                <Grid item xs={12}>
                  <LettersOnlyField
                    name="family_name"
                    control={control}
                    label="Last Name"
                    rules={{ required: 'Last Name is required' }}
                    error={!!errors.family_name}
                    helperText={errors.family_name?.message}
                    autoComplete='family-name'
                  />
                </Grid>
                <Grid item xs={12}>
                  <EmailField
                    name="email"
                    control={control}
                    label="Email Address"
                    rules={{ required: 'Email is required' }}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    autoComplete='email'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    By submitting this form, you consent to receive messages from Coroebus Wealth Management. You can unsubscribe at any time.
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button type="submit" variant="contained" color="secondary" sx={{ mt: 2 }}>
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
          <PythiaSocialLinks />
          <Tooltip title="Hide About Pythia" placement="right">
            <IconButton onClick={() => setPythiaShow(!pythiaShow)}>
              <UnfoldLessIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Collapse>

      {/* Document Section */}
      <Box sx={{ display: 'grid', mt: 3, gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr 1fr' }, gap: 2, alignItems: 'start', p: 3, backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
        <List dense>
          {pdfFiles.slice(0, Math.ceil(pdfFiles.length / 2)).map((file, index) => (
            <ListItem key={index} disableGutters>
              <ListItemButton onClick={() => setPdf(file.file)}>
                <ListItemText primary={file.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <List dense>
          {pdfFiles.slice(Math.ceil(pdfFiles.length / 2)).map((file, index) => (
            <ListItem key={index} disableGutters>
              <ListItemButton onClick={() => setPdf(file.file)}>
                <ListItemText primary={file.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <SocialLinks />
        <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>Giving</Typography>
              <a href="https://coroebusfoundation.org" target="_blank" rel="noopener noreferrer">
                <img src="/assets/cfLogo.png" alt="Philanthropy" style={{ width: '100%', borderRadius: 2 }} />
              </a>
        </Box>
      </Box>

      {/* Footer */}
      <Grid container spacing={3} sx={{ py: 2 }} justifyContent="center">
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2, color: 'white' }}>
          <Typography variant="body2" align="center" sx={{ marginTop: 2 }}>
            © {new Date().getFullYear()} Coroebus Wealth Management, LLC. All rights reserved.
          </Typography>
        </Box>
      </Grid>

      {/* PDF Viewer */}
      <PdfViewer pdfUrl={pdfUrl} open={Boolean(pdfUrl)} handleClose={() => setPdf('')} />

      {/* Access Request Modal */}
      <Modal open={!!open} onClose={handleCancel}>
        <Fade in={!!open}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <AccessRequestForm 
              control={control} 
              handleSubmit={handleSubmit} 
              onSubmit={onSubmit} 
              errors={errors} 
              reset={reset} 
              closeForm={handleCancel} 
              open={!!open}
            />
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default WelcomePage;