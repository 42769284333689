import React from 'react';
import { Dialog, AppBar, Toolbar, IconButton, Typography, Slide, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PdfViewer = ({ pdfUrl, open, handleClose }) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      maxWidth="xl"
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            PDF Viewer
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ flexGrow: 1, width: '100%', height: '90vh' }}>
        {pdfUrl ? (
        <iframe
          src={pdfUrl}
          width="100%"
          height="100%"
          style={{ border: 'none' }}
          title="PDF Viewer"
        />
        ) : null}
      </Box>
    </Dialog>
  );
};

export default PdfViewer;
