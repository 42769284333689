// InfoCard.js
import React from 'react';
import { Paper, Typography, Button, IconButton, Box } from '@mui/material';
import Grid from '@mui/material/Grid';

const InfoCard = ({ title, text, actions = [], children }) => (
  <Grid item xs={12} sm={6} md={4}>
    <Paper sx={{ p: 3, borderRadius: 2, backgroundColor: 'rgba(255, 255, 255, 0.8)', m: 3 }}>
      <Typography variant="h5" align="center">{title}</Typography>
      <Typography variant="body1" mt={1}>{text}</Typography>
      {children}
      {actions.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: actions.length === 1 ? 'center' : 'space-between', mt: 1 }}>
          {actions.map(({ text, link, action, icon }, index) => (
            icon ? (
              <IconButton key={index} onClick={action || undefined} href={!action ? link : undefined}>
                {icon}
              </IconButton>
            ) : (
              <Button
                key={index}
                variant="contained"
                color="primary"
                onClick={action || undefined}
                href={!action ? link : undefined}
              >
                {text}
              </Button>
            )
          ))}
        </Box>
      )}
    </Paper>
  </Grid>
);

export default InfoCard;
