import React from 'react';
import { AppBar, Toolbar } from '@mui/material';
import cwmLogo from '../assets/cwmLogo.png';
import pytHeader from '../assets/logo512.png';

const Header = () => {
    return (
    <AppBar position="relative" color="transparent" elevation={0}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
            <a href="https://coroebuswm.com">
                <img src={cwmLogo} alt="Coroebus Wealth Management" style={{ maxHeight: 50, padding: 10 }} />
            </a>
                <img src={pytHeader} alt="PYTHIA" style={{ maxHeight: 50, padding: 10 }} />
        </Toolbar>
    </AppBar>
    );
};

export default Header;