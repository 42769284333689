// Path: frontend/src/components/LettersOnlyField.jsx

import React from 'react';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

const LettersOnlyField = ({ name, control, label, rules }) => {
  const validateLettersOnly = (value) => /^[a-zA-Z\s]*$/.test(value) || 'Only letters and spaces are allowed';

  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...rules, validate: validateLettersOnly }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          label={label}
          variant="outlined"
          fullWidth
          error={!!error}
          helperText={error ? error.message : ''}
        />
      )}
    />
  );
};

export default LettersOnlyField;
