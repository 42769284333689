import React, { createContext, useContext, useState, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [snackbarQueue, setSnackbarQueue] = useState([]); // Queue to handle multiple messages
  const [currentSnackbar, setCurrentSnackbar] = useState(null); // Currently displayed snackbar

  const displayMessage = (message, severity = 'info') => {
    // Add the new message to the queue
    setSnackbarQueue((prevQueue) => [...prevQueue, { message, severity }]);
  };

  const hideSnackbar = () => {
    setCurrentSnackbar(null); // Clear the current snackbar
  };

  useEffect(() => {
    // Show the next snackbar in the queue if none is currently being displayed
    if (!currentSnackbar && snackbarQueue.length > 0) {
      setCurrentSnackbar(snackbarQueue[0]);
      setSnackbarQueue((prevQueue) => prevQueue.slice(1));
    }
  }, [currentSnackbar, snackbarQueue]);

  return (
    <SnackbarContext.Provider value={{ displayMessage }}>
      {children}
      {currentSnackbar && (
        <Snackbar
          open={!!currentSnackbar}
          autoHideDuration={5000}
          onClose={hideSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={hideSnackbar}
            severity={currentSnackbar.severity}
            sx={{ width: '100%' }}
          >
            {currentSnackbar.message}
          </Alert>
        </Snackbar>
      )}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);