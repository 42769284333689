import React from 'react';
import { Box } from '@mui/material';
import WelcomePage from './pages/welcome-page';
import Header from './components/header';
import ErrorBoundary from './context/error-boundary';
import { SnackbarProvider } from './context/snackbar-context';
import { ThemeProvider } from '@emotion/react';
import theme from './theme';

const App = () => {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <Header />
            <Box
              sx={{
                backgroundImage: "url('/assets/circumpolar.jpg')",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: '100vh', // Ensure full height
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',

              }}
            >
              <WelcomePage />
          </Box>
        </SnackbarProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;