// Path: frontend/src/components/NumericField.jsx

import React from 'react';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

const NumericField = ({ name, control, label, rules }) => {
  const validateNumeric = (value) => /^\d*$/.test(value) || 'Only numeric values are allowed';

  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...rules, validate: validateNumeric }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          label={label}
          variant="outlined"
          fullWidth
          error={!!error}
          helperText={error ? error.message : ''}
        />
      )}
    />
  );
};

export default NumericField;
