import React from 'react';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

const EmailField = ({ name, control, label, rules }) => {
  const validateEmail = (value) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || 'Invalid email address';

  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...rules, validate: validateEmail }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          label={label}
          variant="outlined"
          fullWidth
          error={!!error}
          helperText={error ? error.message : ''}
          type="email"
          autoComplete="email"
        />
      )}
    />
  );
};

export default EmailField;