// Desc: Theme for the application
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1887c1',
      contrastText: '#ffffff', // Ensures readable text on primary backgrounds
    },
    secondary: {
      main: '#ee5da1',
      contrastText: '#ffffff',
    },
    error: {
      main: '#f44336',
      contrastText: '#ffffff',
    },
    background: {
      default: '#f4f6f8', // Light gray background for consistency
      paper: '#ffffff', // White background for Paper components
    },
    text: {
      primary: '#212121', // Dark gray for readability
      secondary: '#757575', // Lighter gray for secondary text
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: { fontSize: '2rem', fontWeight: 700 },
    h2: { fontSize: '1.75rem', fontWeight: 600 },
    h3: { fontSize: '1.5rem', fontWeight: 500 },
    h4: { fontSize: '1.25rem', fontWeight: 500 },
    h5: { fontSize: '1.125rem', fontWeight: 600 },
    h6: { fontSize: '1rem', fontWeight: 400 },
    body1: { fontSize: '1rem', lineHeight: 1.6 },
    body2: { fontSize: '0.875rem', lineHeight: 1.5 },
    button: { textTransform: 'capitalize', fontWeight: 600 },
  },
  spacing: 8, // Defines the default spacing unit (1 = 8px)
  shape: {
    borderRadius: 8, // Rounded corners for a softer UI
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'capitalize',
          fontWeight: 400,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          wordBreak: 'break-word', // Prevents text overflow issues
        },
      },
    },
  },
});

export default theme;